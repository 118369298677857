import * as React from 'react';
import Layout, {
  Column,
  SectionBackground,
  SectionParent,
} from '../components/layout';
import SEO from '../components/seo';
import KanjiverseLogo from '../components/kanjiverse-logo';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Hill from '../images/hill.svg';
import Stars from '../images/stars.svg';
import Toori from '../images/toori.svg';

const Logo = styled('div')`
  transform: scale(1.5);
`;

const ThankyouPage: React.FC = () => {
  return (
    <Layout gradientHeight={[580, 700]}>
      <SEO title="Thank You" />
      <Column>
        <SectionParent height={[580, 700]}>
          <SectionBackground
            height={[580, 700]}
            background={'uncommonGradient2'}
          />
          <Box
            position="absolute"
            bottom={-1}
            left={0}
            display={['none', 'block']}
          >
            <Hill />
          </Box>
          <Box
            position="absolute"
            bottom={130}
            left={100}
            display={['none', 'block']}
          >
            <Toori />
          </Box>
          <Box
            position="absolute"
            bottom={300}
            left={65}
            display={['none', 'block']}
          >
            <Stars />
          </Box>
          <Column
            position="absolute"
            pt={24}
            pl={[2, 0, 16]}
            pr={[2, 8, 0]}
            color="white"
            alignItems={['stretch', 'flex-end']}
            width={[1, 1, 600]}
          >
            <Column alignItems="center" maxWidth={[1, 400]}>
              <Logo>
                <KanjiverseLogo />
              </Logo>
              <Box pt={[6, 6]} />
              <Typography variant="h1" align="center">
                Thank you
              </Typography>
              <Box pt={[1, 2]} />
              <Typography variant="h5" align="center">
                A confirmation email has been sent to your address with some
                useful information about the beta.
              </Typography>
              {/*<Box pt={[6, 4]} />*/}
              {/*<Typography variant="body1" align="center">*/}
              {/*  We would really appreciate if you could take a few minutes to*/}
              {/*  answer this anonymous survey about your Japanese study habits to*/}
              {/*  help us understand better our future users.*/}
              {/*</Typography>*/}
              {/*<Box pt={[3, 4]} />*/}
              {/*<Button*/}
              {/*  target="_blank"*/}
              {/*  variant="contained"*/}
              {/*  color="primary"*/}
              {/*  size="large"*/}
              {/*  href="https://forms.gle/3sU4qFSucwJzkFzw5"*/}
              {/*>*/}
              {/*  Take the survey*/}
              {/*</Button>*/}
            </Column>
          </Column>
        </SectionParent>
      </Column>
    </Layout>
  );
};

export default ThankyouPage;
